$(document).ready(() => {
  $('.sections__nav li a').click((e) => {
    const tabId = $(e.currentTarget).data('target');

    $('.sections__nav li a').removeClass('sections__nav--active');
    $('.sections__content').removeClass('sections__content--active');

    $(e.currentTarget).addClass('sections__nav--active');
    $(`#${tabId}`).addClass('sections__content--active');
  });
});
