const header = $('header');
const headerOffset = header.offset().top;

function calculateAnchorPoints() {
  const targets = $('.anchor').add('#clearnav');

  return targets.map((index) => {
    const element = $(targets[index]);
    const elementOffset = Math.round(element.offset().top);

    return element.data('offset',elementOffset);
  });
}

function activeAnchor(currentPosition) {
  const anchorPoints = calculateAnchorPoints();

  const activeTargets = anchorPoints.filter((index) => {
    return currentPosition >= anchorPoints[index].data('offset');
  });

  return activeTargets[activeTargets.length - 1];
}

function toggleFixedHeader(currentPosition) {
  if (currentPosition > headerOffset) {
    header.addClass('header--fixed');
  } else {
    header.removeClass('header--fixed');
  }
}

function highlightNav(currentPosition) {
  const currentTarget = activeAnchor(currentPosition);

  closeMobileMenu();

  // Clear existing nav highlighting
  const menuLinks = $('.navbar__menu a');
  if (menuLinks.hasClass('navbar__link--active')) {
    menuLinks.removeClass('navbar__link--active');
  }

  // Highlight nav
  if (currentTarget && currentTarget.attr('id') !== 'undefined') {
    const targetElement = currentTarget.attr('id');
    $('.navbar__menu').find(`a[href^='/#${targetElement}']`).addClass('navbar__link--active');
  }
}

function closeMobileMenu() {
  const menu = $('.navbar__menu');
  if (menu.hasClass('navbar__menu--active')) {
    menu.toggleClass('navbar__menu--active');
  }
}

function smoothScroll(hash) {
  $('html, body').animate({
    scrollTop: Math.round($(hash).offset().top)
  }, 800, 'easeInOutCirc', () => {
    window.location.hash = hash;
  });
}

function setHeaderFeatures() {
  const currentPosition = Math.round($(window).scrollTop());

  toggleFixedHeader(currentPosition);
  highlightNav(currentPosition);
}

// Update header depending on scroll position
$(window).on('scroll', () => {
  setHeaderFeatures();
});

$(document).ready(() => {

  // Set header features on load
  setHeaderFeatures();

  // Toggle mobile nav
  $('.navbar__toggle').click(() => {
    $('.navbar__menu').toggleClass('navbar__menu--active');
  });

  // Close mobile menu on "unfocus"
  $('section, footer').click(() => {
    closeMobileMenu();
  });

  // Smooth scroll
  $('a').on('click', (event) => {
    const { currentTarget } = event;
    const { hash } = currentTarget;

    if (hash !== '' && $(hash).length > 0) {
      event.preventDefault();

      closeMobileMenu();
      smoothScroll(hash);
    }
  });
});
